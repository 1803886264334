<template>
  <div ref="ctrl" class="control">
    <audio ref="player" src="/audio/dial.mp3" />
    <audio ref="error" src="/audio/error.mp3" />
    <audio ref="success" src="/audio/success.mp3" />
    <div class="lcd-container">
      <img class="lcd" src="/img/lcd.png" />

      <textarea :value="input" resizable="false" />
    </div>
    <div class="buttons">
      <button
        v-for="b in buttons"
        :key="b"
        @click="handleClick(b)"
        :class="{ shadow: lastChar === b.toString() }"
      />
      <img class="keys" src="/img/layout.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttons: [1, 2, 3, 4, 5, 6, 7, 8, 9, -1, 0, -2],
      input: "",
      lastChar: "",
    };
  },
  methods: {
    handleClick(btn) {
      this.playSound();
      if (btn === -1) {
        this.clear();
      } else if (btn === -2) {
        this.validate();
      } else if (this.input.length < 12) {
        this.input += btn.toString();
        this.lastChar = btn.toString();
      } else {
        this.validate();
      }
    },
    validate() {
      if (
        this.input === "32" ||
        this.input === "322225533555" ||
        this.input === "100000"
      ) {
        this.handleCorrectAnswer();
      } else {
        this.handleWrongAnswer();
      }
    },
    clear() {
      this.input = "";
    },
    playSound() {
      this.$refs.player.pause();
      this.$refs.player.play();
    },
    handleCorrectAnswer() {
      this.input = "SUCCESS 🎁";
      this.$refs.success.play();
      setTimeout(() => {
        this.input = "";
      }, 2000);
    },
    handleWrongAnswer() {
      try {
        window.navigator.vibrate(100);
      } catch (e) {
        console.log("no vibration api");
      }
      this.$refs.error.play();
      this.$refs.ctrl.classList.add("shake");
      setTimeout(() => {
        this.$refs.ctrl.classList.remove("shake");
        this.clear();
      }, 75);
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  height: 100vh;
  background: #a1b8b8;
  overflow: hidden;
}
#app {
  height: 100%;
  padding: 1rem;
}
</style>
<style lang="scss">
.control {
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
}
.buttons {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  aspect-ratio: calc(703 / 918);
  max-height: calc(100% - 125px - 2rem);
}
button {
  background: transparent;
  border: none;
}
button.shadow {
  border-radius: 100%;
  box-shadow: inset 1px 1px 20px 20px #0000006e;
  filter: blur(10px);
}
div.lcd-container {
  position: relative;
  box-shadow: 0px 0px 20px 20px inset black;
}
img.lcd {
  position: absolute;
  mix-blend-mode: difference;
  z-index: 1;
  height: 125px;
  width: 101%;
  object-fit: cover;
  border-radius: 6px;
}
img.keys {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  max-height: 100%;
  border-radius: 10px;
}
textarea {
  width: 100%;
  height: 125px;
  background: greenyellow;
  font-size: 14vw;
  resize: none;
  pointer-events: none;
  line-height: 1.2;
  border-radius: 10px;
  filter: blur(1px);
}
.shake {
  animation: 50ms shake infinite;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
